import React, { useEffect, useState } from "react";

import "./ZJMLogo.css";

type logoProps = {
  height: string;
  width: string;
  auto?: boolean;
};

function Logo(props: logoProps) {
  const [animationPlayed, setAnimationPlayed] = useState(false);
  useEffect(() => {
    if (props.auto && !animationPlayed) setAnimationPlayed(true);
    //eslint-disable-next-line
  }, []);
  return (
    <svg
      // width="288"
      // height="132"
      viewBox="0 0 199 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="logo-frame"
      style={{ height: props.height, width: props.width, maxHeight: "24rem" }}
    >
      <g id="Frame 1">
        <path
          // id={"mSymbol"}
          className={animationPlayed ? "mSymbol mActive" : "mSymbol"}
          d="M100.829 117.022C123.435 118.457 140.757 100.686 148.535 88.8311C149.84 86.8417 148.909 84.2309 146.709 83.3249L145.297 82.7436C143.563 82.0296 141.575 82.6236 140.44 84.1164C130.112 97.6984 115.888 107.022 100.829 107.022C86.6438 107.022 77.5283 102.615 67.315 92.2808C65.3408 90.2832 66.1561 86.956 68.7842 85.9654L118.787 67.1184C122.19 65.8356 122.254 61.0445 118.886 59.6714L68.0666 38.9528C65.659 37.9712 64.7808 34.9701 66.4534 32.9795C71.8578 26.5475 82.4238 17.0214 100.829 17.0214C122.809 17.0214 134.288 31.1134 140.397 40.1644C141.524 41.8335 143.69 42.45 145.505 41.5782L147.387 40.6744C149.314 39.7493 150.182 37.4681 149.161 35.591C142.881 24.0509 126.87 6.2981 100.829 7.02143C75.183 7.73384 58.7353 24.3589 51.9099 35.2882C50.6825 37.2537 51.6552 39.7548 53.8055 40.6184L100.98 59.5655C104.404 60.9406 104.298 65.8233 100.819 67.0498L54.4029 83.4095C52.0315 84.2453 51.0002 87.042 52.3369 89.1716C60.6062 102.347 72.3241 115.212 100.829 117.022Z"
          // fill="#0009D8"
          // fill-opacity="0.5"
        />
        <path
          // id="zSymbol"
          className={animationPlayed ? "zSymbol zActive" : "zSymbol"}
          d="M155.506 63.2794C156.454 40.5247 143.731 25.6355 133.169 18.168C129.747 15.7487 125.506 18.441 125.506 22.6316C125.506 24.3143 126.243 25.9141 127.486 27.0489C140.297 38.7505 143.554 47.0648 145.182 62.3577C145.247 62.9663 145.238 63.5943 145.155 64.2007C143.177 78.7523 139.935 86.2657 132.549 93.8741C129.087 97.4401 123.245 96.3285 120.776 92.015L79.8338 20.4689C77.6969 16.7347 72.9732 15.3516 69.5108 17.9054C58.6728 25.8994 44.6777 41.6306 45.0057 63.2794C45.3237 84.2668 58.3025 99.2714 68.719 107.138C71.882 109.526 76.0057 107.086 76.0057 103.122C76.0057 101.346 75.1083 99.7083 73.7277 98.5912C66.8385 93.0171 56.2206 80.4376 55.5057 63.2794C54.9109 49.0039 62.5714 37.5294 69.4858 30.6643C72.7492 27.4241 77.93 28.642 80.2141 32.6335L121.557 104.88C123.742 108.698 128.627 110.071 132.148 107.435C141.922 100.117 154.548 86.259 155.506 63.2794Z"
          // fill="#00ACE2"
          // fill-opacity="0.5"
        />
      </g>
    </svg>
  );
}

export default Logo;
